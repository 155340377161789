import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Modal } from "@mui/joy";
import { db } from "../../firebase"; // Firebase configuration import
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import ModeIcon from "@mui/icons-material/Mode";
import { useNavigate } from "react-router-dom";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function KycVerificationList() {
  const navigate = useNavigate();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
console.log('settlements',settlements);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "kycVerifications"));
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSettlements(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data.");
        setLoading(false);
      }
    };

    fetchSettlements();
  }, []);

  const updateOldSettlementStatus = async (id, newStatus) => {
    try {
      const oldSetelRef = doc(db, 'kycVerifications', id);
      await updateDoc(oldSetelRef, { status: newStatus });

      setSettlements((prevOldSettlement) =>
        prevOldSettlement.map((old) =>
          old.id === id ? { ...old, status: newStatus } : old
        )
      );
      toast.success(`KYC Verification ${id} updated to ${newStatus}!`);
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status!');
    }
  };

  const handleAccept = async (id) => {
    updateOldSettlementStatus(id, 'Accepted');

  };
  
  const handleReject = async (id) => {
    updateOldSettlementStatus(id, 'Rejected');

  };

  

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />

      {/* Page Content */}
      <Box
        sx={{
          padding: 2,
          margin: "0 auto",
          maxWidth: "1200px",
          color: "#fff",
          textAlign: "left",
        }}
      >
        <Typography
          level="h2"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            fontWeight: "bold",
            marginBottom: 3,
            color: "#fff",
          }}
        >
          KYC Verification List
        </Typography>
        {/* Responsive Table */}
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              color: "#FFFFFF",
              backgroundColor: "#333",
            }}
          >
            <thead>
              <tr>
                {[
                  "SL.No",
                  "country",
                  "Email",
                  "Type of ID Proof",
                  "ID Card Number",
                  "ID Image front",
                  "ID Image back",
                  "ID Image hold",
                  "statuse",
                  "Action",
                ].map((header) => (
                  <th key={header} style={tableHeaderStyle}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {settlements.map((settlement,i) => (
                <tr key={settlement.id}>
                <td style={tableCellStyle}>{i + 1}</td>
                  <td style={tableCellStyle}>{settlement.country}</td>
                  <td style={tableCellStyle}>{settlement.userEmail}</td>
                  <td style={tableCellStyle}>{settlement.idProofType}</td>
                  <td style={tableCellStyle}>{settlement.idcardNumber}</td>
                  <td style={tableCellStyle}>
                    <img
                      src={settlement.idProofImageFront}
                      alt="Dashboard"
                      onClick={() =>
                        handleImageClick(settlement.idProofImageFront)
                      }
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td style={tableCellStyle}>
                    <img
                      src={settlement.idProofImageBack}
                      alt="Wallet"
                      onClick={() => handleImageClick(settlement.idProofImageBack)}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td style={tableCellStyle}>
                    <img
                      src={settlement.idProofImageHolding
}
                      alt="Wallet"
                      onClick={() => handleImageClick(settlement.idProofImageHolding
)}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td style={tableCellStyle}>{settlement.status}</td>
                  <td style={tableCellStyle}>
                    <div style={{display:'flex'}}>
                    <Button
                      variant="soft"
                      color="success"
                      size="sm"
                      onClick={() => handleAccept(settlement.id)}
                      sx={{ marginRight: "8px" }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="soft"
                      color="danger"
                      size="sm"
                      onClick={() => handleReject(settlement.id)}
                    >
                      Reject
                    </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>

      {/* Modal for Image Preview */}
      <Modal open={!!selectedImage} onClose={closeModal}>
        <Box
          sx={{
            padding: 2,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "90vw",
          }}
        >
          <img
            src={selectedImage}
            alt="Enlarged"
            style={{
              maxWidth: "90%",
              maxHeight: "30vh",
              borderRadius: "8px",
            }}
          />
          <Button onClick={closeModal} color="danger" sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: "8px",
  borderBottom: "2px solid #555",
  textAlign: "left",
  backgroundColor: "#444",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #555",
  textAlign: "center",
};

export default KycVerificationList;
