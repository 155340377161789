import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  getDoc
} from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';
import { CssVarsProvider } from '@mui/joy';
import framesxTheme from '../../theme';

const DolarRateMaster = () => {
  const [dollarRates, setDollarRates] = useState([]);
  const [newRate, setNewRate] = useState('');
  const [newStatute, setNewStatute] = useState('Take'); // Default to 'Take'
  const [editingRate, setEditingRate] = useState(null);
  const [updatedRate, setUpdatedRate] = useState('');
  const [updatedStatute, setUpdatedStatute] = useState('');

  // Fetch dollar rates from Firestore
  useEffect(() => {
    const fetchDollarRates = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'dolarRate'));
        const rates = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDollarRates(rates);
      } catch (error) {
        console.error('Error fetching dollar rates:', error);
        toast.error('Failed to fetch dollar rates.');
      }
    };

    fetchDollarRates();
  }, []);

  // Add a new dollar rate with statute
  const handleAddRate = async () => {
    if (!newRate) {
      toast.error('Please enter a dollar rate.');
      return;
    }
  
    // Check if a rate with the selected statute already exists
    const existingRate = dollarRates.find((rate) => rate.statute === newStatute);
    if (existingRate) {
      toast.error(
        `You already have a ${newStatute} rate. Please modify or delete the existing rate before adding a new one.`
      );
      return;
    }
  
    try {
      const docRef = await addDoc(collection(db, 'dolarRate'), {
        rate: parseFloat(newRate),
        statute: newStatute, // Save statute in DB
        timestamp: serverTimestamp(),
      });
  
      // Fetch the newly added document to get the actual Firestore timestamp
      const newDoc = await getDoc(docRef);
      if (newDoc.exists()) {
        setDollarRates([...dollarRates, { id: newDoc.id, ...newDoc.data() }]);
      }
  
      setNewRate('');
      setNewStatute('Take'); // Reset statute
      toast.success('Dollar rate added successfully!');
    } catch (error) {
      console.error('Error adding dollar rate:', error);
      toast.error('Failed to add dollar rate.');
    }
  };

  // Update a dollar rate
  const handleUpdateRate = async () => {
    if (!updatedRate) {
      toast.error('Please enter a new dollar rate.');
      return;
    }

    try {
      await updateDoc(doc(db, 'dolarRate', editingRate.id), {
        rate: parseFloat(updatedRate),
        statute: updatedStatute,
        timestamp: serverTimestamp(),
      });

      // Fetch the updated document to get the correct timestamp
      const updatedDoc = await getDoc(doc(db, 'dolarRate', editingRate.id));
      if (updatedDoc.exists()) {
        setDollarRates(
          dollarRates.map((rate) =>
            rate.id === editingRate.id ? { id: updatedDoc.id, ...updatedDoc.data() } : rate
          )
        );
      }

      setEditingRate(null);
      setUpdatedRate('');
      setUpdatedStatute('');
      toast.success('Dollar rate updated successfully!');
    } catch (error) {
      console.error('Error updating dollar rate:', error);
      toast.error('Failed to update dollar rate.');
    }
  };

  // Delete a dollar rate
  const handleDeleteRate = async (id) => {
    try {
      await deleteDoc(doc(db, 'dolarRate', id));
      setDollarRates(dollarRates.filter((rate) => rate.id !== id));
      toast.success('Dollar rate deleted successfully!');
    } catch (error) {
      console.error('Error deleting dollar rate:', error);
      toast.error('Failed to delete dollar rate.');
    }
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Dollar Rate Master</h2>

          {/* Add Dollar Rate */}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
              marginBottom: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <input
              type="number"
              placeholder="Enter Dollar Rate"
              value={newRate}
              onChange={(e) => setNewRate(e.target.value)}
              style={{
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                width: '200px',
              }}
            />
            <select
              value={newStatute}
              onChange={(e) => setNewStatute(e.target.value)}
              style={{
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                width: '150px',
              }}
            >
              <option value="Take">Take</option>
              <option value="Give">Give</option>
            </select>
            <button
              onClick={handleAddRate}
              style={{
                padding: '10px 20px',
                borderRadius: '5px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Add Rate
            </button>
          </div>

          {/* Dollar Rate Table */}
          <div style={{ overflowX: 'auto' }}>
          <table
    style={{
      width: '100%',
      borderCollapse: 'collapse',
      backgroundColor: 'white',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      minWidth: '600px', // Ensures the table doesn't shrink too much
    }}
  >
    <thead>
      <tr style={{ backgroundColor: '#f4f4f4' }}>
        <th style={{ padding: '10px', textAlign: 'left' }}>No.</th>
        <th style={{ padding: '10px', textAlign: 'left' }}>Dollar Rate</th>
        <th style={{ padding: '10px', textAlign: 'left' }}>Statute</th>
        <th style={{ padding: '10px', textAlign: 'left' }}>Last Updated</th>
        <th style={{ padding: '10px', textAlign: 'left' }}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {dollarRates.map((rate, index) => (
        <tr key={rate.id} style={{ borderBottom: '1px solid #ddd' }}>
          <td style={{ padding: '10px' }}>{index+1}</td>
          <td style={{ padding: '10px' }}>
            {editingRate && editingRate.id === rate.id ? (
              <input
                type="number"
                value={updatedRate}
                onChange={(e) => setUpdatedRate(e.target.value)}
                style={{
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  width: '100px',
                }}
              />
            ) : (
              rate.rate
            )}
          </td>
          <td style={{ padding: '10px' }}>
            {editingRate && editingRate.id === rate.id ? (
              <select
                value={updatedStatute}
                onChange={(e) => setUpdatedStatute(e.target.value)}
                style={{
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              >
                <option value="Take">Take</option>
                <option value="Give">Give</option>
              </select>
            ) : (
              rate.statute || 'N/A'
            )}
          </td>
          <td style={{ padding: '10px' }}>
            {rate.timestamp && rate.timestamp.seconds
              ? new Date(rate.timestamp.seconds * 1000).toLocaleString('en-IN', {
                  timeZone: 'Asia/Kolkata',
                  hour12: true,
                })
              : 'N/A'}
          </td>
          <td style={{ padding: '10px' }}>
            {editingRate && editingRate.id === rate.id ? (
              <>
                <button
                  onClick={handleUpdateRate}
                  style={{
                    padding: '8px 12px',
                    marginRight: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => setEditingRate(null)}
                  style={{
                    padding: '8px 12px',
                    borderRadius: '5px',
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    setEditingRate(rate);
                    setUpdatedRate(rate.rate);
                    setUpdatedStatute(rate.statute);
                  }}
                  style={{
                    padding: '8px 12px',
                    marginRight: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#2196F3',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteRate(rate.id)}
                  style={{
                    padding: '8px 12px',
                    borderRadius: '5px',
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Delete
                </button>
              </>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
          </div>
        </div>
      </CssVarsProvider>
    </div>
  );
};

export default DolarRateMaster;
