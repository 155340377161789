import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  Select,
  Option,
  // Modal,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import SupportSystem from '../support/SupportSystem';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';

const LiveBalanceRequest = () => {
  // const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const { user } = useAuth();
  // Define validation schema
  const [showModal, setShowModal] = useState(false);
  const validationSchema = Yup.object().shape({
    // mobileNumber: Yup.string()
    //   .matches(/^\d{10}$/, "Invalid mobile number")
    //   .required("Mobile No. is required"),
    // email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required('Password is required'),
    investmentAmount: Yup.number()
      .typeError('Investment Amount must be a number')
      .positive('Investment Amount must be positive')
      .required('Investment Amount is required'),
    brokerId: Yup.string().required('Broker Name is required'),
    paymentModeId: Yup.string().required('Payment Mode is required'),
    mt5Id: Yup.string().notRequired(),
    mt5Password: Yup.string().notRequired(),
    tModeType: Yup.string().required(),
  });
  const storage = getStorage();
  const [inrAmount, setInrAmount] = useState(''); // State for INR Amount
  const [usdtAmount, setUsdtAmount] = useState(''); // State for USDT Amount
  // Define state variables for form data, errors, form validity, payment types, and broker names
  const [formData, setFormData] = useState({
    mobileNumber: '',
    email: '',
    password: '',
    investmentAmount: 0,
    investmentdate: '',
    brokerId: '',
    paymentModeId: '',
    md5Userid: '',
    mt5Password: '',
    tModeType: '',
    imageUrl: '',
    paymentType: 'Investment-with-Principal'
  });
  console.log('formData', formData);
  
  const [errors, setErrors] = useState({});
  const [req, setReq] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // const [paymentTypes, setPaymentTypes] = useState([]);
  const [brokerNames, setBrokerNames] = useState([]);
  const [curentUserData, setCurentUserData] = useState([]);
  const tenantsCollection = collection(db, 'members');
  
  const [dollarRate ,setDollarRate] = useState(); // Store the Dollar Rate here (you can fetch this dynamically if needed)

  const paymentTypes = [
    { paymentModeId: '1', paymentModeType: 'Cash' },
    { paymentModeId: '2', paymentModeType: 'TRC20' },
    // { paymentModeId: '3', paymentModeType: 'Card' },
  ];

  const types = [
    { typeModeId: '1', tModeType: 'IB' },
    { typeModeId: '2', tModeType: 'Investment' },
  ];
  const handleViewRequest = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };


  useEffect(() => {
    const fetchDollarRate = async () => {
      try {
        const dollarRatesCollection = collection(db, 'dolarRate'); // Replace 'dolarRate' with your Firestore collection name
        const dollarRatesSnapshot = await getDocs(dollarRatesCollection);
        const dollarRatesData = dollarRatesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // Find the rate with the "Take" status
        const takeRate = dollarRatesData.find((rate) => rate.statute === 'Take');
        if (takeRate) {
          setDollarRate(takeRate.rate); // Set the rate in the state
        } else {
          toast.error('No dollar rate with "Take" status found.');
        }
      } catch (error) {
        console.error('Error fetching dollar rate:', error);
        toast.error('Failed to fetch dollar rate.');
      }
    };
  
    fetchDollarRate();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchMembers(tenantsCollection);
        setCurentUserData(data[0]);

        console.log('111----------------->', data);
      }
    };
    fetchData(); // Call the async function
  }, []);

  async function fetchMembers(ref) {
    const snapshot = query(ref, where('email', '==', user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      const abc = { id: doc.id, ...doc.data() };
      results.push(abc);
    });

    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  }

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        // Fetch the members collection from Firestore
        const querySnapshot = await getDocs(
          collection(db, 'liveAccountRequests')
        );
        const liveAccountRequestsList = querySnapshot.docs
          .map((doc) => ({
            id: doc.id, // Firestore document ID
            ...doc.data(), // All other data in the document
          }))
          .filter((doc) => doc.createdUserId === user.uid);

        console.log(
          'Fetched liveAccountRequests from Firebase:',
          liveAccountRequestsList
        );
        setReq(liveAccountRequestsList); // Set members in state
      } catch (error) {
        console.error(
          'Error fetching liveAccountRequests from Firebase:',
          error
        );
        toast.error('Error fetching liveAccountRequests from Firebase!');
      }
    };

    fetchRequest();
  }, []);

 const handleInrChange = (e) => {
  const inrValue = e.target.value;
  setInrAmount(inrValue);

  // Calculate USDT Amount
  const calculatedUsdt = inrValue && dollarRate ? (inrValue / dollarRate).toFixed(2) : '';
  setUsdtAmount(calculatedUsdt);

  // Update the formData with the calculated USDT amount
  setFormData((prevData) => ({
    ...prevData,
    investmentAmount: calculatedUsdt,
  }));
};

  const handleInputChange = async (event) => {
    const { name, value, files } = event.target;

    if (name === 'image') {
      // If an image file is selected, upload it to Firebase Storage
      const file = files[0];
      if (file) {
        try {
          const imageRef = ref(storage, `images/${file.name}`);
          await uploadBytes(imageRef, file);
          const downloadURL = await getDownloadURL(imageRef);
          setFormData((prevData) => ({ ...prevData, imageUrl: downloadURL })); // Update formData with the image URL
          toast.success('Image uploaded successfully!');
        } catch (error) {
          console.error('Error uploading image:', error);
          toast.error('Failed to upload image');
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Add a state for managing submission
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const requestData = {
        ...formData,
        createdBy: curentUserData.name,
        createdByBvId: curentUserData.BVID,
        createdUserId: user.uid,
        createdAt: new Date().toISOString(),
        status: 'Pending',
        ActiveStatus: 'In-Active',
        paymentType: 'Investment-with-Principal',
      };

      // Save the same data in the fundBalance collection
      const fundBalanceDocRef = doc(collection(db, 'fundBalance'));
      await setDoc(fundBalanceDocRef, requestData);
      
      // Save the data in the liveAccountRequests collection
      await addDoc(collection(db, 'liveAccountRequests'), requestData);
      
      toast.success('Form submitted successfully!');
      setFormData({
        mobileNumber: '',
        email: '',
        password: '',
        investmentAmount: 0,
        investmentdate: '',
        brokerId: '',
        paymentModeId: '',
        md5Userid: '',
        mt5Password: '',
        tModeType: '',
        imageUrl: '',
        paymentType: 'Investment-with-Principal'
      }); // Reset form
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.message || 'Submission failed!');
    } finally {
      setIsSubmitting(false); // Enable the submit button
    }
  };

  // Effect to check form validity
  useEffect(() => {
    validationSchema.isValid(formData).then((valid) => {
      setIsValid(valid);
    });
  }, [formData, validationSchema]);

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1, // Places the image behind the content
        }}
      />
      <CssVarsProvider>
        <Box sx={{ padding: 2 }}>
          <Typography
            level="h3"
            style={{
              color: '#FFFFFF',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
            fontWeight="lg"
          >
            LIVE ACCOUNT BALANCE REQUEST
          </Typography>
          <Typography
            level="body-sm"
            style={{
              color: '#FFFFFF',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
            my={2}
          >
            Live Account balance request from your account
          </Typography>
          <Sheet
            variant="outlined"
            sx={{
              padding: 3,
              borderRadius: 'sm',
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white
              backdropFilter: 'blur(10px)', // Optional: Adds a blur effect for the content behind
              boxShadow: 'none',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 2 }}>
              <Button
                type="submit"
                color="warning"
                variant="solid"
                sx={{ marginTop: 2 }}
                // onClick={handleViewRequest}
                onClick={handleViewRequest}
              >
                View Requests
              </Button>
            </Box>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Payment Mode:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="paymentModeId"
                    value={formData.paymentModeId}
                    onChange={handleInputChange}
                    required
                    style={{ background: '#1a2a3385', color: 'white' }}
                  >
                    <option value="">Select Payment Mode</option>
                    {paymentTypes.map((type) => (
                      <option
                        key={type.paymentModeId}
                        value={type.paymentModeType}
                      >
                        {type.paymentModeType}
                      </option>
                    ))}
                  </select>
                  {errors.paymentModeId && (
                    <Typography color="error" variant="body2">
                      {errors.paymentModeId}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Select Image:</FormLabel>
                  <Input
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.imageUrl && (
                    <Typography color="error">{errors.imageUrl}</Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Investment Amount (INR):</FormLabel>
                  <Input
                    type="number"
                    value={inrAmount}
                    onChange={handleInrChange}
                    placeholder="Enter amount in INR"
                    sx={{
                      backgroundColor: '#fff',
                      color: '#000',
                      borderRadius: '8px',
                      padding: '8px',
                    }}
                  />
                </FormControl>

                {/* Investment Amount (USDT) */}
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Investment Amount (USDT):</FormLabel>
                  <Input
                    name="investmentAmount"
                    // value={formData.investmentAmount}
                    // onChange={handleInputChange}
                    value={usdtAmount}
                    readOnly
                    required
                    type="number"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.investmentAmount && (
                    <Typography color="error" variant="body2">
                      {errors.investmentAmount}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Investment Date:</FormLabel>
                  <Input
                    name="investmentdate"
                    value={formData.investmentdate}
                    onChange={handleInputChange}
                    required
                    type="date"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.investmentAmount && (
                    <Typography color="error" variant="body2">
                      {errors.investmentAmount}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Broker Name:</FormLabel>
                  <input
                    type="text"
                    className="px-2 py-2 border rounded bg-transparent border-white-600"
                    name="brokerId"
                    value={formData.brokerId}
                    onChange={handleInputChange}
                    placeholder="Enter Broker Name"
                    required
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.brokerId && (
                    <Typography color="error" variant="body2">
                      {errors.brokerId}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Password:</FormLabel>
                  <Input
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    type="password"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.password && (
                    <Typography color="error" variant="body2">
                      {errors.password}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>MT5 Id. (optional):</FormLabel>
                  <Input
                    name="md5Userid"
                    value={formData.md5Userid}
                    onChange={handleInputChange}
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.mt5Id && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Id}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>MT5 Password. (optional):</FormLabel>
                  <Input
                    name="mt5Password"
                    value={formData.mt5Password}
                    onChange={handleInputChange}
                    type="password"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.mt5Password && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Password}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Type:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="tModeType"
                    value={formData.tModeType}
                    onChange={handleInputChange}
                    required
                    style={{ background: '#1a2a3385', color: 'white' }}
                  >
                    <option value="">Select Type</option>
                    {types.map((t) => (
                      <option key={t.typeModeId} value={t.tModeType}>
                        {t.tModeType}
                      </option>
                    ))}
                  </select>
                  {errors.tModeType && (
                    <Typography color="error" variant="body2">
                      {errors.tModeType}
                    </Typography>
                  )}
                </FormControl>
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="solid"
                  sx={{ marginTop: 2 }}
                  disabled={!isValid} // Disable button if form is not valid
                >
                  Submit Request
                </Button>
              </Box>
            </form>
          </Sheet>
        </Box>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={showModal}
          onClose={handleClose}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Sheet
            variant="outlined"
            sx={{ maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              sx={{ fontWeight: 'lg', mb: 2 }}
            >
              Account Request Details
            </Typography>
            {Array.isArray(req) && req.length > 0 ? (
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>
                      Broker ID
                    </th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>
                      Investment Amount
                    </th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>
                      Payment Mode
                    </th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>
                      Status
                    </th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>
                      Image
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {req.map((request, index) => (
                    <tr key={index}>
                      <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                        {request.brokerId}
                      </td>
                      <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                        {request.investmentAmount}
                      </td>
                      <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                        {request.paymentModeId}
                      </td>
                      <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                        {request.status}
                      </td>
                      <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                        <img
                          src={request.imageUrl}
                          alt="req"
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '4px',
                          }} // Adjust size and styling as needed
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No account requests available.</p>
            )}
          </Sheet>
        </Modal>
        <SupportSystem />
      </CssVarsProvider>
    </div>
  );
};

export default LiveBalanceRequest;
